





















import {
  defineComponent,
  computed,
} from '@nuxtjs/composition-api';
import {
  SfRating,
  SfDivider,
} from '@storefront-ui/vue';
import FtRating from "~/components/customSFUI/vue/src/components/atoms/FtRating/FtRating.vue";

export default defineComponent({
  name: 'FortytwoCategoryProductRating',
  components: {
    SfRating,
    SfDivider,
    FtRating,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isShowRating: Boolean
  },
  setup(props) {
    const productRatingScore = computed(() => (props.product?.rating_summary ?? 0));
    const productRating = parseFloat(props.product.rating_summary) % 1 === 0 ? parseFloat(props.product.rating_summary).toFixed(1) : parseFloat(props.product.rating_summary).toFixed(2);
    const qtySold = computed(() => {
      const qty = Number(props.product.total_qty_sold);
      
      if (qty >= 1 && qty <= 999) {
        return `${qty}`;
      } else if (qty >= 1000 && qty <= 9999) {
        const formatted = (qty / 1000).toFixed(1);
        return `${formatted}k+`;
      } else if (qty >= 10000) {
        const formatted = Math.floor(qty / 1000);
        return `${formatted}k+`;
      } else {
        return '0';
      }
    });
    const isShowQtySold = computed(() => props.product?.total_qty_sold > 0);
    
    return {
      productRatingScore,
      productRating,
      qtySold,
      isShowQtySold,
    };
  },
});
