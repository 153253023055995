













import { defineComponent, PropType, ref, useFetch } from '@nuxtjs/composition-api';
import type { ProductWithCommonProductCardProps } from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import { merge } from "lodash-es";

export default defineComponent({
  name: 'FortytwoCategoryProductDimension',
  props: {
    product: {
      type: Object as PropType<ProductWithCommonProductCardProps>,
      required: true,
    },
  },
  setup(props) {
    const dimension = ref('');    
    const dimensions = ref({
      'W': props.product.width ?? 0, 
      'H': props.product.height ?? 0, 
      'D': props.product.depth ?? 0, 
      'L': props.product.length ?? 0, 
      'Ø': props.product.diameter ?? 0
    });
    const bedSize = ref('');
    const bedSizeArray = ref([]);
    const validBedSizes = {
      'Single': 'S',
      'Super Single': 'SS', 
      'Queen': 'Q', 
      'King': 'K', 
      'UK': 'UK Size',
    };
    const bedSizeConfigOptions = ref(props.product?.configurable_options?.filter(filter => filter.attribute_code === 'bed_size')?.[0]?.values.map(option => option.label));
    
    useFetch(() => {
      for(const [dimensionLabel, dimensionValue] of Object.entries(dimensions.value)) {
        if(dimensionValue) {
          dimensions.value =  merge({}, dimensions.value, { [dimensionLabel] : dimensionLabel + dimensionValue });
        } else {
          Reflect.deleteProperty(dimensions.value, dimensionLabel);
        }
      }
      dimension.value = Object.values(dimensions.value).join(' ');

      if (bedSizeConfigOptions.value && bedSizeConfigOptions.value.length > 0) {      
        // only get bed size start with Single/ Super Single/ Queen/ King/ UK
        for(const size of bedSizeConfigOptions.value) {
          for(const [validBedSize, validBedSizeValue] of Object.entries(validBedSizes)) {
            if(size.startsWith(validBedSize)) {
              bedSizeArray.value.push(validBedSizeValue);
              break;
            }
          } 
        }
        // remove duplicate value
        bedSizeArray.value = [...new Set(bedSizeArray.value)];

        bedSize.value = bedSizeArray.value.join(' / ');
      }
    });

    return {
      dimension,
      bedSize,
    };
  },
});
