import { render, staticRenderFns } from "./FtRating.vue?vue&type=template&id=49d4e582&functional=true&"
import script from "./FtRating.vue?vue&type=script&lang=js&"
export * from "./FtRating.vue?vue&type=script&lang=js&"
import style0 from "./FtRating.vue?vue&type=style&index=0&id=49d4e582&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports