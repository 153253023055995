<template>
  <div
    class="sf-product-card"
    :class="{ 'has-colors': colors.length }"
    data-testid="product-card"
  >
    <ProductLabel :product="product" :imageSize="imageSize" v-if="product && imageSize"/>
    <div class="sf-wishlist-container">
      <SfButton
        :aria-label="`${ariaLabel} ${title}`"
        :class="[wishlistIconClasses, { 'display-none': !wishlistIcon }]"
        data-testid="product-wishlist-button"
        @click="toggleIsInWishlist"
      >
        <slot name="wishlist-icon" v-bind="{ currentWishlistIcon }">
          <SfIcon
            v-if="currentWishlistIcon !== false"
            :icon="currentWishlistIcon"
            data-test="sf-wishlist-icon"
          />
        </slot>
      </SfButton>
    </div>
    <div class="sf-product-card__image-wrapper">
      <CategoryProductImage :product="product" :itmImage="itmImage" :itmUID="itmUID" 
        @click:select-item-ga4="$emit('click:select-item-ga4')"
        @click:wishlist-login="$emit('click:wishlist-login')"
        @click:wishlist="$emit('click:wishlist', product)"
        @updImageSize="imageSize = $event"
      />
    </div>
    <slot name="title" v-bind="{ title, link }">
      <SfButton
        :link="link"
        class="sf-button--pure sf-product-card__link"
        data-testid="product-link"
        v-on="$listeners"
      >
        <span class="sf-product-card__title">
          {{ title }}
        </span>
      </SfButton>
    </slot>
    <CategoryProductPrice :product="product" />
    <CategoryProductRating :product="product" :isShowRating="isShowRating"/>
    <CategoryProductDimension :product="product" />
    <CategoryProductLeadTime :product="product" />
    <CategoryProductSwatch :product="product" 
      @fetchProductImage="$emit('fetchProductImage', $event)" 
      @passProductDetail="$emit('passProductDetail', $event)"
    />
    <CategoryProductStockStatus :product="product" />
  </div>
</template>
<script>
import { colorsValues as SF_COLORS } from "@storefront-ui/shared/variables/colors";
import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";
import FtPrice from "~/components/customSFUI/vue/src/components/atoms/FtPrice/FtPrice.vue";
import SfRating from "@storefront-ui/vue/src/components/atoms/SfRating/SfRating.vue";
import SfImage from "@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue";
import SfCircleIcon from "@storefront-ui/vue/src/components/atoms/SfCircleIcon/SfCircleIcon.vue";
import SfBadge from "@storefront-ui/vue/src/components/atoms/SfBadge/SfBadge.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
import SfColorPicker from "@storefront-ui/vue/src/components/molecules/SfColorPicker/SfColorPicker.vue";
import SfColor from "@storefront-ui/vue/src/components/atoms/SfColor/SfColor.vue";
import CategoryProductImage from '~/components/FortyTwo/category/CategoryProductImage.vue';
import CategoryProductPrice from '~/components/FortyTwo/category/CategoryProductPrice.vue';
import CategoryProductDimension from '~/components/FortyTwo/category/CategoryProductDimension.vue';
import CategoryProductLeadTime from '~/components/FortyTwo/category/CategoryProductLeadTime.vue';
import CategoryProductSwatch from '~/components/FortyTwo/category/CategoryProductSwatch.vue';
import CategoryProductStockStatus from '~/components/FortyTwo/category/CategoryProductStockStatus.vue';
import CategoryProductRating from '~/components/FortyTwo/category/CategoryProductRating.vue';
import ProductLabel from '~/modules/fortytwo/product/components/ProductLabel.vue';

export default {
  name: "FtProductCard",
  components: {
    FtPrice,
    SfRating,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfBadge,
    SfButton,
    SfColorPicker,
    SfColor,
    CategoryProductImage,
    CategoryProductPrice,
    CategoryProductDimension,
    CategoryProductLeadTime,
    CategoryProductSwatch,
    CategoryProductStockStatus,
    CategoryProductRating,
    ProductLabel,
  },
  props: {
    image: {
      type: [Array, Object, String],
      default: "",
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    badgeLabel: {
      type: String,
      default: "",
    },
    badgeColor: {
      type: String,
      default: "",
    },
    colors: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    link: {
      type: [String, Object],
      default: null,
    },
    /**
     * Link element tag
     * @deprecated will be removed in 1.0.0 use slot to replace content
     */
    linkTag: {
      type: String,
      default: undefined,
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: "heart",
    },
    isInWishlistIcon: {
      type: [String, Array],
      default: "heart_fill",
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      default: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: "",
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
    stock_status: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      required: true,
    },
    itmImage: {
      type: String,
    },
    itmUID: {
      type: String,
    },
    isShowRating: Boolean
  },
  data() {
    return {
      isAddingToCart: false,
      openColorPicker: false,
      imageSize: null,
    };
  },
  computed: {
    isSFColors() {
      return SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass() {
      return this.isSFColors ? `${this.badgeColor.trim()}` : "";
    },
    currentWishlistIcon() {
      return this.isInWishlist ? this.isInWishlistIcon : this.wishlistIcon;
    },
    showAddedToCartBadge() {
      return !this.isAddingToCart && this.isAddedToCart;
    },
    ariaLabel() {
      return this.isInWishlist ? "Remove from wishlist" : "Add to wishlist";
    },
    wishlistIconClasses() {
      const defaultClass = "sf-button--pure sf-product-card__wishlist-icon";
      return `${defaultClass} ${this.isInWishlist ? "on-wishlist" : ""}`;
    },
    showBadge() {
      return this.colors.length > 5;
    },
  },
  methods: {
    toggleIsInWishlist() {
      if (!this.isAuthenticated) {
        this.$emit("click:wishlist-login", !this.isInWishlist);
      } else {
        this.$emit("click:wishlist", !this.isInWishlist);
      }
    },
    onAddToCart(event) {
      event.preventDefault();
      this.isAddingToCart = true;
      setTimeout(() => {
        this.isAddingToCart = false;
      }, 1000);
      this.$emit("click:add-to-cart");
      this.$emit("click:add-to-cart-ga4");
    },
    handleSelectedColor(colorIndex) {
      if (this.colors.length > 0) {
        this.colors.map((color, i) => {
          if (colorIndex === i) {
            this.$emit("click:colors", color);
            this.openColorPicker = false;
          }
        });
      }
    },
    toggleColorPicker() {
      this.openColorPicker = !this.openColorPicker;
    },
  },
};
</script>
<style lang="scss">
// @import "~@storefront-ui/shared/styles/components/organisms/SfProductCard.scss";
@import "~/components/customSFUI/shared/styles/components/organisms/FtProductCard/FtProductCard.scss";

.sf-product-card {
  &:hover .sf-product-card__wishlist-icon {
    display: block;
  }

  .mpproductlabel-labels {
    z-index: 1;
    position: relative;
    min-height: 20px;
    max-height: 20px;
    @include for-desktop {
      position: absolute;
      min-height: unset;
      max-height: unset;
      top: var(--spacer-sm);
      left: var(--spacer-sm);
    }
  }

  .sf-product-card__wishlist-icon {
    z-index: 1;
    top: var(--spacer-xs);
    right: var(--spacer-xs);
    @include for-desktop {
      top: var(--spacer-sm);
      right: var(--spacer-sm);
    }
  }
}
</style>
