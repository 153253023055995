<template functional>
  <div
    :class="[data.class, data.staticClass, 'sf-rating']"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot
      v-for="index in Math.ceil($options.finalScore(props.score, props.max))"
      name="icon-positive"
    >
      <component
        :is="injections.components.FtStarIcon"
        :key="`p${index}`"
        class="sf-rating__icon"
        :icon="props.icon"
        :coverage="
          index === Math.ceil($options.finalScore(props.score, props.max)) &&
          $options.finalScore(props.score, props.max) % 1 > 0
            ? $options.finalScore(props.score, props.max) % 1
            : 1
        "
      />
    </slot>
    <slot
      v-for="index in parseInt($options.finalMax(props.max), 10) -
      Math.ceil($options.finalScore(props.score, props.max))"
      name="icon-negative"
    >
      <component
        :is="injections.components.FtStarIcon"
        :key="`n${index}`"
        class="sf-rating__icon sf-rating__icon--negative"
        :icon="props.icon"
      />
    </slot>
  </div>
</template>
<script>
import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";
import FtStarIcon from "~/components/customSFUI/vue/src/components/atoms/FtStarIcon/FtStarIcon.vue";
export default {
  name: "FtRating",
  inject: {
    components: {
      default: { SfIcon, FtStarIcon },
    },
  },
  props: {
    max: {
      type: Number,
      default: 5,
    },
    score: {
      type: Number,
      default: 1,
    },
    icon: {
      type: String,
      default: "star",
    },
  },
  finalScore(score, max) {
    return !score || score < 0 || (score > max && max > 0) || max <= 0
      ? 0
      : score;
  },
  finalMax(max) {
    return !max || max <= 0 ? 1 : max;
  },
};
</script>
<style lang="scss">
// @import "~@storefront-ui/shared/styles/components/atoms/SfRating.scss";
@import "~/components/customSFUI/shared/styles/components/atoms/FtRating/FtRating.scss";
</style>
