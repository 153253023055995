


















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { Product } from '~/modules/catalog/product/types';
import { getPrice as getProductPrice } from '~/modules/catalog/product/getters/productGetters';
import { getConfigurableProductPriceCommand } from '~/modules/catalog/pricing/getConfigurableProductPriceCommand';
import { getConfigurableProductSpecialPriceCommand } from '~/modules/catalog/pricing/getConfigurableProductSpecialPriceCommand';

export default defineComponent({
  name: 'FortyTwoProductPrice',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    bundleOptionPrice: {
      type: Number,
      default: 0,
    },
    customOptionPrice: {
      type: Number,
      default: 0,
    },
    pageType: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const bundleMainPrice = computed(() => props.product?.bundle_main_price?.price ?? 0);
    const bundleMainSpecialPrice = computed(() => props.product?.bundle_main_price?.final_price < props.product?.bundle_main_price?.price 
    ? props.product?.bundle_main_price?.final_price : null);

    const productPrice = computed(() => props.product.__typename != 'ConfigurableProduct' 
    ? ((props.product.__typename == 'BundleProduct' && props.pageType == 'product') ? bundleMainPrice.value : getProductPrice(props.product).regular) + props.bundleOptionPrice + props.customOptionPrice 
    : getConfigurableProductPriceCommand(props.product) + props.customOptionPrice);

    const productSpecialPrice = computed(() => props.product.__typename != 'ConfigurableProduct' 
    ? ( 
      (props.product.__typename == 'BundleProduct' && props.pageType == 'product')
      ? bundleMainSpecialPrice.value && bundleMainSpecialPrice.value + props.bundleOptionPrice + props.customOptionPrice
      : getProductPrice(props.product).special && getProductPrice(props.product).special + props.bundleOptionPrice + props.customOptionPrice
    )
    : getConfigurableProductSpecialPriceCommand(props.product) && getConfigurableProductSpecialPriceCommand(props.product) + props.customOptionPrice);

    const bundleMinPrice = computed(() => (props.product.__typename == 'BundleProduct' && props.pageType == 'product') ? getProductPrice(props.product).regular : 0);

    const productCrossedOutPrice = computed(() => props.product?.crossed_out_list_price ?? 0);
    const hideCrossOutPrice = computed(() => props.product?.crossed_out_list_price ? props.product?.crossed_out_list_price <= getProductPrice(props.product).regular : true );

    return {
        productPrice,
        productSpecialPrice,
        productCrossedOutPrice,
        hideCrossOutPrice,
        bundleMinPrice,
    }
  }
});
