











































import {
  ref,
  defineComponent,
  watchEffect,
  computed,
  onBeforeMount,
} from '@nuxtjs/composition-api';
import {
  SfImage,
  SfButton,
  SfIcon,
} from '@storefront-ui/vue';
import { useImage } from '~/composables';

export default defineComponent({
  name: 'FortytwoCategoryProductImage',
  components: {
    SfImage,
    SfButton,
    SfIcon,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    itmImage: {
      type: String,
    },
    itmUID: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { imageSizes: { productCard: imageSize } } = useImage();

    const placeholder = ref('/custom/fortytwo-loading-icon.gif');

    const itemImage = ref(props.product.commonProps.image);

    watchEffect( () => {
      if (props.product.uid === props.itmUID) {
        itemImage.value = props.itmImage;
      }
    });

    onBeforeMount(async () => {
      emit("updImageSize", imageSize);
    });

    return {
      imageSize,
      itemImage,
      placeholder,
    };
  },
});
